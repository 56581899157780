import React from 'react';
import { PageProps } from 'gatsby';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions, HeroSectionColors } from '../../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../../localization/translations';
import './style.scss';
import PageMenu from '../../../components/PageMenu/PageMenu';
import aboutUsMenu from '../../../data/page-menus/about-us';
import { useLocaleContext } from '../../../context/Locale';
import { LocaleTag } from '../../../../config/locales';
import ExternalLink from '../../../components/ExternalLink/ExternalLink';

type Props = PageProps & WithTranslation;

const FraudAlert: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('fraudAlert.seo.title'),
    },
    page: {
      title: t('fraudAlert.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.CHERRY_RED,
        heroImage: {
          fileName: 'hero-image-3.jpg',
          alt: 'Image',
        },
      },
    },
  };

  const rcmpFraudAlertLink =
    activeLocaleTag === LocaleTag.EN
      ? 'http://www.rcmp-grc.gc.ca/scams-fraudes/index-eng.htm'
      : 'http://www.rcmp-grc.gc.ca/scams-fraudes/index-fra.htm';

  const getContentBlocks = () => {
    const contentBlocks: Array<any> = t('fraudAlert.contentBlock.blocks', { returnObjects: true });
    return contentBlocks.map((blockItemString: string, i) => (
      <li key={`${blockItemString}-${i}`}>{blockItemString}</li>
    ));
  };

  return (
    <Layout options={layoutOptions} className="FraudAlert">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <p>{t('fraudAlert.body')}</p>
            {t('fraudAlert.contentBlock.heading')}
            <br />
            <ul>{getContentBlocks()}</ul>
            <p>
              <Trans
                i18nKey="fraudAlert.moreInfo"
                t={t}
                values={{ websiteLabel: rcmpFraudAlertLink }}
              >
                <ExternalLink href={rcmpFraudAlertLink} />
              </Trans>
            </p>
          </section>
          <aside className="column column-33">
            <PageMenu menu={aboutUsMenu} />
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.ABOUT_US)(FraudAlert);
